.well {
  background: none;
  border-radius: 90%;
  border: 1px solid black;
  font-size: smaller;
  width: 45px;
  height: 45px;
  margin: 1px;
  color: black;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.plate {
  display: flex;
  flex-direction: column;
  width: 551px;
  height: 367px;
  border: 1px solid black;
}

.button {
    appearance: none;
    text-align: left;
}

.colorSelector {
  width: 50px;
  height: 50px;
  border: none;
  margin: 15px;
  cursor: pointer;
  margin-top: flex;
}

.selectPlate {
  border: none;
  margin-left: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  /* width: 100px; */
  height: 50px;
  text-align: center;
  background-color: transparent;
  border: 2px solid black;
  color: black;
  font-size: 32px;
  font-weight: bold;
}