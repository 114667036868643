* {
  font-family:Arial;
}

.App {
  text-align: center;
}

.AdminLoginPanel {
  text-align: center;
}

.Page-Name {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


#page {
  background-image: linear-gradient(#F4E9C3, white);
  height: 400px;
  
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
