/* src\Header.css */

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #252522;
}

.nav-div {
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
  border-bottom: #2625223e 2px solid;
  scroll-behavior: smooth;
}

.nav-sub-btn {
  background: none;
  border: none;
  margin: 15px;
  cursor: pointer;
  margin-left: auto;
  /* margin-right: 0; */
  color: #262522;
  font-size: 16px;
  font-weight: bold;
}

.nav-register-btn {
  background-color: #262522;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
}
